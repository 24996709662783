<template>
  <div class="dashboard">
    <dashboard-info-block />
    <dashboard-charts />
  </div>
</template>

<script>
import DashboardCharts from "./DashboardCharts.vue";
import DashboardInfoBlock from "./DashboardInfoBlock";

export default {
  name: "dashboard",
  components: {
    DashboardCharts,
    DashboardInfoBlock,
  },
  watch: {
    "$i18n.locale"() {
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
    &__title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
