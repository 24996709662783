<template>
  <div class="row row-equal mt-3">
    <div class="flex xl12 xs12">
      <div class="row">
        <div class="flex xs12 sm6 xl3" v-for="(stat, idx) in stats" :key="idx">
          <va-card
            gradient
            class="mb-4 stats-card box-shadow"
            :color="stat.color"
          >
            <va-card-content style="line-height: 1.5">
              <img class="float-end" width="40" :src="stat.icon" alt="" />
              <p class="display-2 py-2" style="color: white">
                {{ stat.value }}
              </p>
              <p style="color: white">{{ $t(stat.text) }}</p>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </div>
    <!-- <div class="flex xl12 xs12">
      <va-card>
        <va-card-title style="font-size: 1.2rem !important">{{
          $t("requests")
        }}</va-card-title>
        <va-card-content>
          <div class="row">
            <div
              class="flex xs12 sm3"
              v-for="(info, idx) in requestTiles"
              :key="idx"
            >
              <va-card class="mb-4" :color="info.color">
                <va-card-content>
                  <p class="display-2 mb-0" style="color: white">
                    {{ info.value }}
                  </p>
                  <p style="color: white">{{ $t(info.text) }}</p>
                </va-card-content>
              </va-card>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div> -->
    <va-modal v-model="modal">
      <div style="position: relative">
        <va-button
          @click="showPrevImage"
          color="#fff"
          icon="chevron-left"
          flat
          style="position: absolute; top: 50%"
        />
        <va-button
          @click="showNextImage"
          color="#fff"
          icon="chevron-right"
          flat
          style="position: absolute; top: 50%; right: 0"
        />
        <transition>
          <img
            :src="images[currentImageIndex]"
            style="height: 50vh; max-width: 100%"
          />
        </transition>
      </div>
    </va-modal>
  </div>
</template>

<script>
import {
  APP_STATS,
  PROFITS_BETWEEN_TWO_DATES,
} from "@/graphql/queries/stats";
import { request } from "graphql-request";
import { useGlobalConfig } from "vuestic-ui";

export default {
  name: "DashboardInfoBlock",
  data() {
    return {
      stats: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      this.getAppStats();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    showPrevImage() {
      this.currentImageIndex = !this.currentImageIndex
        ? this.images.length - 1
        : this.currentImageIndex - 1;
    },
    showNextImage() {
      this.currentImageIndex =
        this.currentImageIndex === this.images.length - 1
          ? 0
          : this.currentImageIndex + 1;
    },
    async getAppStats() {
      const statsResponse = await request(this.$store.state.appUrl, APP_STATS);
      const profitsResponse = await request(
        this.$store.state.appUrl,
        PROFITS_BETWEEN_TWO_DATES,
        {
          start_date: new Date("2022-01-01")
            ?.toISOString()
            .split("T")
            .join(" ")
            .split(".")[0],
          end_date: new Date()
            ?.toISOString()
            .split("T")
            .join(" ")
            .split(".")[0],
        }
      );
      this.stats = [
        {
          color: "#1aaa20",
          value: profitsResponse.amount.profit,
          text: this.$t("profits"),
          icon: "/img/profits.png",
        },
        {
          color: "#033b84",
          value: statsResponse.upcoming.count,
          text: this.$t("upcoming"),
          icon: "/img/champs.png",
        },
        {
          color: "#027898",
          value: statsResponse.users.count,
          text: this.$t("users"),
          icon: "/img/users.png",
        },
        {
          color: "#433e6c",
          value: statsResponse.ongoing.count,
          text: this.$t("ongoing"),
          icon: "/img/champs.png",
        },
      ];
    },
  },
  computed: {
    theme() {
      return useGlobalConfig().getGlobalConfig().colors || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.row-separated {
  .flex + .flex {
    border-left: 1px solid var(--va-background);
  }

  // @include media-breakpoint-down(xs) {
  //   p:not(.display-2) {
  //     font-size: 0.875rem;
  //   }
  // }
}

.rich-theme-card-text {
  line-height: 24px;
}

// .dashboard {
//   .va-card__header--over {
//     // @include media-breakpoint-up(md) {
//     //   padding-top: 0 !important;
//     // }
//   }

//   .va-card__image {
//     // @include media-breakpoint-up(md) {
//     //   padding-bottom: 0 !important;
//     // }
//   }
//   // .image-card {
//   //   position: relative;
//   //   .va-button {
//   //     position: absolute;
//   //   }
//   // }
// }
.stats-card:hover {
  transform: translateY(-5px);
  transition: all ease-in-out 0.2s;
}
.box-shadow {
  box-shadow: -11px 9px 20px 0px #00000070;
}
</style>
