<template>
  <div class="row row-equal">
    <div class="flex xs12 xl6">
      <va-card v-if="barChartData">
        <va-card-title>
          <h1>{{ this.$t("championships") }}</h1>
          <va-date-input
            v-model="barChartRange"
            :formatDate="formatDate"
            :parseDate="parseDate"
            @update:modelValue="rangeChange('barChart')"
          />
        </va-card-title>
        <va-card-content>
          <va-chart
            class="chart"
            ref="lineChart"
            :data="barChartData"
            :options="barChartOptions"
            type="vertical-bar"
          />
        </va-card-content>
      </va-card>
    </div>

    <div class="flex xs12 xl6">
      <va-card class="d-flex">
        <va-card-title>
          <h1>{{ $t("online_users") }}</h1>
          <va-date-input
            v-model="donutChartRange"
            :formatDate="formatDate"
            :parseDate="parseDate"
            @update:modelValue="rangeChange('donutChart')"
          />
        </va-card-title>
        <va-card-content v-if="donutChartData">
          <va-chart
            class="chart chart--donut"
            :data="donutChartData"
            :options="donutChartOptions"
            type="donut"
          />
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import VaChart from "@/components/va-charts/VaChart.vue";
import {
  CHAMPSCOUNT_BETWEEN_TWO_DATES,
  SESSIONS_STATS,
} from "@/graphql/queries/stats";
import { request } from "graphql-request";

export default {
  name: "dashboard-charts",
  components: { VaChart },
  data() {
    return {
      champsDataForBarChart: [],
      barChartData: null,
      barChartOptions: null,
      barChartRange: {
        start: this.$store.getters.getFirstOfMonth,
        end: this.$store.getters.getEndDate,
      },
      donutChartData: null,
      donutChartOptions: null,
      donutChartRange: {
        start: this.$store.getters.getFirstOfMonth,
        end: this.$store.getters.getEndDate,
      },
    };
  },
  async mounted() {
    await this.getChampsDataInRange();
    await this.getUsersSessionsInRange();
  },

  methods: {
    printChart() {
      const win = window.open("", "Print", "height=600,width=800");
      win.document.write(`<br><img src='${this.donutChartDataURL}'/>`);
      // TODO: find better solution how to remove timeout
      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
      }, 200);
    },
    async getChampsDataInRange() {
      const response = await request(
        this.$store.state.appUrl,
        CHAMPSCOUNT_BETWEEN_TWO_DATES,
        {
          start_date: this.$store.getters.getFirstOfMonth
            ?.toISOString()
            .split("T")
            .join(" ")
            .split(".")[0],
          end_date: this.$store.getters.getEndDate
            ?.toISOString()
            .split("T")
            .join(" ")
            .split(".")[0],
        }
      );
      this.champsDataForBarChart = response.champsCount_between_two_dates;

      this.barChartData = {
        labels: this.champsDataForBarChart.allChamps?.map((champ) =>
          this.getMonth(champ.month)
        ),
        datasets: [
          {
            label: this.$t("approved"),
            backgroundColor: "#01579bd6",
            borderColor: "transparent",
            data: this.champsDataForBarChart.allChamps?.map(
              (champ) => champ.number
            ),
          },
          {
            label: this.$t("denied"),
            backgroundColor: "#e70000c4",
            borderColor: "transparent",
            data: this.champsDataForBarChart.rejectedChamps?.map(
              (champ) => champ.number
            ),
          },
        ],
      };
      this.barChartOptions = {
        scales: {
          yAxes: [
            {
              id: "first-Y-axis",
              type: "linear",
              ticks: {
                min: 0,
              },
            },
          ],
        },
        animation: {
          duration: 1500,
          easing: "linear",
        },
      };
    },
    async getUsersSessionsInRange() {
      const response = await request(this.$store.state.appUrl, SESSIONS_STATS, {
        start_date: this.$store.getters.getFirstOfMonth
          ?.toISOString()
          .split("T")
          .join(" ")
          .split(".")[0],
        end_date: this.$store.getters.getEndDate
          ?.toISOString()
          .split("T")
          .join(" ")
          .split(".")[0],
      });

      this.donutChartOptions = {
        animation: {
          duration: 1500,
          easing: "easeInOutCirc",
          onProgress: function (animation) {
            // progress.value = animation.animationObject.currentStep / animation.animationObject.numSteps;
          },
        },
      };

      this.donutChartData = {
        labels: [this.$t("users"), this.$t("guests")],
        datasets: [
          {
            backgroundColor: ["#023047", "#FFB703"],
            data: [response.onlineUsers.count, response.onlineGuests.count],
          },
        ],
      };
    },
    getMonth(idx) {
      idx =
        idx == "January"
          ? 1
          : idx == "February"
          ? 2
          : idx == "March"
          ? 3
          : idx == "April"
          ? 4
          : idx == "May"
          ? 5
          : idx == "June"
          ? 6
          : idx == "July"
          ? 7
          : idx == "August"
          ? 8
          : idx == "September"
          ? 9
          : idx == "October"
          ? 10
          : idx == "November"
          ? 11
          : 12;
      var objDate = new Date();
      objDate.setDate(1);
      objDate.setMonth(idx - 1);

      var locale = this.$i18n.locale == "gb" ? "en-us" : "ar",
        month = objDate.toLocaleString(locale, { month: "long" });

      return month;
    },
    rangeChange(chart_type) {
      if (chart_type !== "barChart") {
        console.log(chart_type);
        this.$store.commit("setDateRange", this.donutChartRange);
        this.getUsersSessionsInRange();
        return;
      }
      this.$store.commit("setDateRange", this.barChartRange);
      this.getChampsDataInRange();
    },

    // Time Foramtting
    parseDate(date) {
      const [day, month, year] = date.split("-");

      return new Date(year, month - 1, day);
    },
    formatDate(date) {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
.text-right {
  text-align: right;
}
.va-chart canvas {
  background: radial-gradient(#000, #fff);
}
</style>
